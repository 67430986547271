<template>
  <section class="region" id="subjects">
    <h2 class="region__title">Ваши субъекты<span class="green-dot">.</span></h2>
    <div v-if="subjects.length" class="region__slides">
      <div class="swiper" ref="items">
        <div class="swiper-wrapper">
          <div class="swiper-slide" v-for="(item, i) in subjects" :key="i">
            <ImgComponent :head_img="item.img" class="region__slide-item" />
            <div class="region__slide-infos">
              <span>{{ item.title }} — {{ item.price | formatPrice }} ₽</span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <span v-else class="region__empty">У вас нет купленных регионов</span>
    <div class="region__pagination"></div>
    <img src="/static/svg/default/overview-background.svg" alt="My Projects" class="region__background" />
  </section>
</template>

<script>
import ImgComponent from "components/ImgComponent.vue";
import MY_SUBJECTS from "gql/my_subjects.graphql";

export default {
  name: "MySubjectsPage",
  components: { ImgComponent },
  async asyncData({ apollo, store }) {
    await apollo.defaultClient
      .query({
        query: MY_SUBJECTS,
        context: {
          headers: {
            Authorization: "Bearer " + store.state.apollo_token,
          },
        },
      })
      .then(({ data }) => {
        store.state.my_subjects = data.my_subjects;
      });
  },
  data() {
    return {
      loading: false,
      green: false,
      swiper: null,
    };
  },
  computed: {
    subjects() {
      return this.$store.state.my_subjects;
    },
  },
  mounted() {
    require(["swiper/swiper-bundle.esm.browser.js"], ({ Swiper }) => {
      this.swiper = new Swiper(this.$refs.items, {
        slidesPerView: 1,
        spaceBetween: 16,
        centeredSlides: true,
        pagination: {
          el: ".region__pagination",
          dynamicBullets: true,
        },
        breakpoints: {
          // 315: {
          //   slidesPerView: 1,
          //   spaceBetween: 0,
          // },
          350: {
            slidesPerView: 1,
            spaceBetween: 0,
          },
          380: {
            slidesPerView: 1.2,
            spaceBetween: 0,
          },
          420: {
            slidesPerView: 1.4,
            spaceBetween: 16,
          },
          450: {
            slidesPerView: 1.5,
            spaceBetween: 16,
          },
          490: {
            slidesPerView: 1.6,
            spaceBetween: 16,
          },
          560: {
            slidesPerView: 1.8,
            spaceBetween: 16,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 2.5,
            spaceBetween: 20,
          },
          960: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1240: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1440: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          1570: {
            slidesPerView: 5,
            spaceBetween: 50,
          },
        },
      });
      if (this.windowSize > 1570) {
        if (this.subjects.length >= 5) {
          this.swiper.slideTo(3);
        }
      } else if (this.windowSize > 1440) {
        if (this.subjects.length >= 4) {
          this.swiper.slideTo(2);
        }
      } else {
        if (this.subjects.length >= 3) {
          this.swiper.slideTo(2);
        }
      }
    });
  },
};
</script>

<style lang="stylus">
@import "~swiper/swiper-bundle.min.css"
.region {
  display flex
  flex-direction column
  align-items center
  justify-content center
  width 100%
  position relative
  margin-bottom 100px
  +below(768px) {
    gap 20px
    margin-bottom 40px
  }

  &__background {
    absolute left bottom
    transform translateY(100%)
  }

  &__title {
    font-weight: 800;
    font-size: 2.125em;
    text-align: center;
    color: var(--main);
    margin 0
    +below(768px) {
      font-size 1.8em
    }
  }

  &__empty{
    font-size 18px
    margin-top: 60px
  }

  .stats {
    padding 0 100px
    max-width 1000px
    display grid
    grid-template-columns repeat(3, 1fr)
    grid-gap 30px
    margin-top 50px
    +below(1200px) {
      padding 40px
      grid-template-columns repeat(2, 1fr)
    }
    +below(768px) {
      padding 20px
      grid-template-columns 1fr
    }
    +below(560px) {
      padding 16px
    }
  }

  &__slides {
    position relative
    width 100%

    .swiper-slide {
      width auto
      display flex
      flex-direction column
      justify-content center
      align-items center
      height 100%
      transition var(--transition)
      padding 30px 0
      +above(1641px) {
        padding 50px 0
        &.swiper-slide-active {
          transform scale(1.1)
          padding 60px 0
        }
      }

      img {
        max-width 280px
        max-height 575px
        transition var(--transition)
        +above(1641px) {
          filter: drop-shadow(0 0 20px #0000003b);
        }
      }

      .region__slide-item {
        border-radius 10px
        max-width 308px
        height 500px
        max-height 500px
        margin-bottom 40px
        +below(310px) {
          padding 0 8px
        }
      }
      .region__slide-infos {
        width 100vh
        display none
        flex-direction column
        align-items center
        gap 15px

        & .btn {
          padding 0 55px
        }
      }

      &-active {
        .region__slide-infos {
          display flex
        }
      }
    }
  }

  &__pagination {
    margin 0 auto
    left 0 !important
    transform none !important

    .swiper-pagination-bullet {
      width 20px
      height 20px
      background var(--main)
      opacity 0.3
    }

    .swiper-pagination-bullet-active {
      background var(--green)
      opacity 1
    }

  }
}
</style>
